@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background-color: #e1ffed;
}

/* .header-area__subtitle {
  font-size: 1em;
  font-family: "Courier New", "Courier", "monospace";
  color: #26a383;
  min-height: 3em;
}

.header-area__price-clock {
  font-size: 3em;
  font-weight: bold;
  color: #26a383;
}

.avocalculator-form__header {
  color: #26a383;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.3em;
  font-family: "Courier New", "Courier", "monospace";
  margin-bottom: 1.3em;
}

.avocalculator-form__generic-input {
  padding: 0.5em;
  width: 80%;
  color: #66b59d;
  font-size: 1.3em;
  font-family: "Courier New", "Courier", "monospace";
  margin-left: 0.5em;
  border-bottom: 1px solid #ccc;
}

.avocalculator-form__houseprice {

  border: 1px solid #ccc;
}

.form-control {
  color: #26a383;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2em;
  font-family: "Courier New", "Courier", "monospace";
  background-color: #e1ffed;
  border: 0;
  box-shadow: none;
}

.form-group label {
  margin-left: 0.8em;
  color: #66b59d;
  font-size: 0.8em;
  font-weight: normal;
}

input[type="text"]:focus {
  outline: none;
}

.avocalculator-results p {
  color: #66b59d;
  font-size: 1em;
  font-family: "Courier New", "Courier", "monospace";
}
.avocalculator-results__header {
  color: #26a383;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.3em;
  font-family: "Courier New", "Courier", "monospace";
  margin-bottom: 1.3em;
}

.avocalculator-results__deposit-value {
  font-size: 3em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #26a383;
  display: block;
}

.avocalculator-results__value {
  font-size: 1.3em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #26a383;
}

.avocalculator-results__total-years {
  font-size: 3em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #26a383;
  display: block;
}

.avocalculator-results__total-cost {
  font-size: 3em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #26a383;
  display: block;
}

.avocalculator-results__seperator {
  margin: 0;
  margin-bottom: 1em;
  max-width: 3em;
}

hr {
  margin: 25px auto 0;
  border-width: 1px;
  border-color: #ccc;
}

hr.light {
  border-color: #ccc;
}

.menu-item {
  margin-bottom: 1em;
}

.menu-item__title {
  text-transform: uppercase;
  font-size: 1.3em;
  font-family: "Courier New", "Courier", "monospace";
  color: #66b59d;
}

.menu-item__description {
  font-family: "Courier New", "Courier", "monospace";
  color: #9b9b9b;
}

.menu-item__today-price {
  font-size: 2em;
  color: #26a383;
}

.menu-item__today-price-description {
  font-size: 1.3em;
  font-family: "Courier New", "Courier", "monospace";
  font-weight: bold;
  padding-left: 1em;
  color: #26a383;
}

.menu-area__item-seperator {
  margin: 0;
  margin-bottom: 1em;
  max-width: 3em;
}

.header-seperator__hr {
  margin-bottom: 2em;
} */
